import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { Title } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';

import { toSignal } from '@angular/core/rxjs-interop';

import { OivCoreSharedFacade, RouteHelper, TealiumTrackingFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { mobiIconMenu } from '@mobi/rwc-utils-icons-jslib';

import { MobiUiLanguage } from '@mobi/rwc-ui-components-ng-jslib';

import { FooterComponent } from './layout/ui/footer/footer.component';
import { HeaderComponent, HeaderIcon } from './layout/ui/header/header.component';

import * as de from './app.translation.de.json';
import * as fr from './app.translation.fr.json';
import * as it from './app.translation.it.json';

@Component({
    standalone: true,
    imports: [RouterModule, CommonModule, FooterComponent, HeaderComponent],
    selector: 'oiv-viewer-b2c-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    @ViewChild('header', { static: true }) headerComponent!: HeaderComponent;
    #title = inject(Title);
    #oivCoreSharedFacade = inject(OivCoreSharedFacade);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #routeHelper = inject(RouteHelper);
    #router = inject(Router);

    #appTitle = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.title'), {
        initialValue: 'Allgemeine Bedingungen',
    });
    #appMobiliar = toSignal(this.#oivCoreSharedFacade.translatedMessage('oiv.app.mobiliar'), {
        initialValue: 'die Mobiliar',
    });

    isDisplayIcon = computed(() => {
        // Display the menu icon only on the AVB page and on mobile
        return (
            this.#routeHelper.isAvbPage(this.#oivCoreSharedFacade.currentUrl()) && this.#oivCoreSharedFacade.isMobile()
        );
    });

    constructor() {
        this.#oivCoreSharedFacade.translations = { de: de, fr: fr, it: it };

        effect(() => {
            this.#title.setTitle(`${this.#appTitle()} | ${this.#appMobiliar()}`);
        });
        this.#triggerClosedOverlayEffect();
    }

    ngOnInit() {
        this.#oivCoreSharedFacade.source = 'B2C';
        this.#oivCoreSharedFacade.setScreenMode();
    }

    backToHomePage() {
        const homePageUrl = this.#routeHelper.getHomePageURL(
            this.#router,
            this.#oivCoreSharedFacade.applicationType(),
            this.#oivCoreSharedFacade.isApprovalMode(),
        );
        this.#tealiumTrackingFacade.trackButtonClickEvent('header-logo-click', homePageUrl);
        this.#router.navigate([homePageUrl]);
        this.#oivCoreSharedFacade.closeOverlay();
    }

    onHeaderIconClick(headerIcon: HeaderIcon) {
        const icon = headerIcon.icon;
        if (icon.name === mobiIconMenu.name) {
            this.#tealiumTrackingFacade.trackButtonClickEvent(mobiIconMenu.name, '');
            this.#oivCoreSharedFacade.openOverlay();
        } else {
            this.#oivCoreSharedFacade.closeOverlay();
        }
    }

    #triggerClosedOverlayEffect() {
        // Close the x icon and replace by menu icon when the overlay is closed
        effect(() => {
            if (!this.#oivCoreSharedFacade.isOverlayOpen()) {
                this.headerComponent.enableMenuIcon();
            }
        });
    }

    updateLanguage(language: MobiUiLanguage) {
        const route = this.#routeHelper.getRoutePathOnProdStage(language);
        if (route) {
            window.location.href = `https://${route}`;
        } else {
            this.#oivCoreSharedFacade.changeLanguage(language);
        }
        this.#oivCoreSharedFacade.updateLibLanguage(language);
        this.#tealiumTrackingFacade.trackChangeLanguage(language);
    }
}
