{
  "chat_with_avb_failed.generic_error": "La funzione Q&A non è disponibile",
  "chat_with_avb_failed.index_not_found": "La funzione Q&A non è disponibile",
  "chat_with_avb_failed.rate_limit_exceeded": "La funzione Q&A non è disponibile",
  "chat_with_avb_failed.service_request_error": "La funzione Q&A non è disponibile",
  "chat_with_avb_failed.value_error.invalid_language_or_text_reference": "La funzione Q&A non è disponibile",
  "oiv.aiSearch.console.createNewConversation": "Creare una nuova conversazione",
  "oiv.aiSearch.console.firstQuestion": "Inserire una nuova domanda",
  "oiv.aiSearch.console.followUpQuestion": "Inserire la domanda successiva",
  "oiv.aiSearch.console.sendMessage": "Inviare",
  "oiv.aiSearch.disclaimer": "Alle domande rispondiamo con l’aiuto dell’intelligenza artificiale. Le risposte non hanno validità legale. Non è consentito inserire dati personali nella domanda.",
  "oiv.aiSearch.filter.chosenTopic": "Capitolo selezionato",
  "oiv.aiSearch.filter.selectAllTopics": "Tutti i capitoli",
  "oiv.aiSearch.filter.selectTopic": "Selezionare un capitolo",
  "oiv.aiSearch.followUpQuestionSeparator": "Domanda successiva",
  "oiv.aiSearch.mobiAi": "Mobi-AI",
  "oiv.aiSearch.newConversationSeparator": "Nuova domanda",
  "oiv.aiSearch.question": "Domanda",
  "oiv.aiSearch.reference": "Fonti",
  "oiv.aiSearch.Sie": "Lei",
  "oiv.aiSearch.suggestion": "Proposte",
  "oiv.aiSearch.suggestion.bird": "[Not used anymore] I danni allo stabile causati dagli escrementi di uccelli sono assicurati?",
  "oiv.aiSearch.suggestion.blockedWaterPipes": "[Not used anymore] La pulizia delle condutture d’acqua intasate è assicurata?",
  "oiv.aiSearch.suggestion.liability": "[Not used anymore] Fino a quale somma di costruzione è coperta la responsabilità civile in qualità di committente?",
  "oiv.aiSearch.suggestion.lossOfRentalIncome": "[Not used anymore] La perdita del canone di locazione per affitti non pagati è assicurata?",
  "oiv.aiSearch.title": "Domanda e risposta",
  "oiv.app.mobiliar": "la Mobilière",
  "oiv.app.title": "Condizioni generali",
  "oiv.button.close": "Chiudere",
  "oiv.error.aiSearch.filterIsMissing.description": "Si prega di selezionare un capitolo.",
  "oiv.error.aiSearch.filterIsMissing.title": "Nessun capitolo selezionato",
  "oiv.error.aiSearch.notAvailable.description": "Riprovare tra qualche minuto o contattare il Service Desk.",
  "oiv.error.aiSearch.notAvailable.title": "La funzione Q&A non è disponibile",
  "oiv.error.avbNotFound": "CGA non trovate",
  "oiv.error.forbidden": "Non si dispone dell’autorizzazione per accedere a questa risorsa",
  "oiv.error.general": "Spiacenti, qualcosa è andato storto",
  "oiv.error.indexInfoNotFound": "Indice di ricerca non trovato",
  "oiv.error.invalidFeedbackCategory": "Failed to send the feedback",
  "oiv.feedback.category.no_answer_received": "Non risponde alla domanda",
  "oiv.feedback.category.objectively_incorrect": "Formalmente non corretto",
  "oiv.feedback.category.other_reason": "Altro motivo",
  "oiv.feedback.category.style_not_good": "Non mi piace lo stile",
  "oiv.feedback.category.too_comprehensive": "Troppo ampio",
  "oiv.feedback.input.placeholder": "Ulteriori dettagli",
  "oiv.feedback.introduction": "I feedback ci aiutano a migliorare il tool.",
  "oiv.feedback.send.fail": "Invio del feedback non riuscito",
  "oiv.feedback.send.success": "Feedback inviato correttamente",
  "oiv.feedback.title": "Feedback",
  "oiv.footer.contactAndFeedback": "Servizi e contatto",
  "oiv.footer.contactAndFeedback.link": "https://www.mobiliare.ch/contatto",
  "oiv.footer.dataProtection": "Protezione dei dati",
  "oiv.footer.dataprotection.link": "https://www.mobiliare.ch/protezione-dei-dati",
  "oiv.footer.imprint": "Impressum",
  "oiv.footer.imprint.link": "https://www.mobiliare.ch/impressum",
  "oiv.footer.instruction": "Avvertenze per l’uso",
  "oiv.footer.instruction.p1": "Si prega di considerare che la rappresentazione delle Condizioni generali digitali può essere diversa su terminali e browser differenti.",
  "oiv.footer.instruction.p2": "Raccomandiamo di scaricare la versione PDF delle Condizioni generali nella rispettiva lingua e di salvarla sul proprio dispositivo per assicurarsi di avere a disposizione la versione giuridicamente vincolante.",
  "oiv.footer.instruction.p3": "Se a causa di errori o equivoci dovuti alla rappresentazione HTML dovessero emergere domande o dubbi, consigliamo di fare riferimento alla versione PDF giuridicamente vincolante delle Condizioni generali.",
  "oiv.footer.legalInfo": "Informazioni legali",
  "oiv.footer.legalInfo.link": "https://www.mobiliare.ch/aspetti-giuridici",
  "oiv.homepage.button.question": "((not in use)) Domande",
  "oiv.homepage.head.title": "Informazioni per la clientela e Condizioni generali",
  "oiv.homepage.info.paragraph.b2c": "Domande sull’assicurazione? Le Condizioni generali di assicurazione permettono di visualizzare le informazioni principali.",
  "oiv.homepage.info.paragraph.b2e": "Domande sull’assicurazione? Le Condizioni generali di assicurazione permettono di visualizzare le informazioni principali.",
  "oiv.homepage.notFeelLikeWriting": "((not in use)) Nessuna voglia di scrivere?",
  "oiv.homepage.p.goToTopic": "((not in use)) Nessun problema! È possibile accedere direttamente a un tema.",
  "oiv.homepage.placeholder.textarea": "((not in use)) Esempio di domanda che trasmette l’idea...",
  "oiv.homepage.title.content": "Sommario",
  "oiv.indexSearch.error.text": "Riprovare tra qualche minuto.",
  "oiv.indexSearch.error.title": "Spiacenti, qualcosa è andato storto",
  "oiv.indexSearch.results": "Risultati per",
  "oiv.indexSearch.title": "Ricerca",
  "oiv.message.copy": "Copiato!",
  "oiv.message.urlCopy": "URL copiato!",
  "oiv.navigation.splitButton.menu": "Sommario",
  "oiv.navigation.splitButton.qa": "Q&A",
  "oiv.navigation.splitButton.search": "Ricerca",
  "oiv.navigation.title.contact": "Contatto",
  "oiv.navigation.title.contact.link": "https://www.mobiliare.ch/contatto",
  "oiv.navigation.title.downloadPDF": "Scaricare PDF",
  "oiv.navigation.title.furtherTopics": "Altri temi",
  "oiv.navigation.title.goToCustomerPortal": "Al portale clienti",
  "oiv.navigation.title.goToCustomerPortal.link": "https://www.mobiliare.ch/login",
  "oiv.navigation.title.requestAdvice": "Richiedere aiuto",
  "oiv.navigation.title.visitProductPage": "Pagina prodotti",
  "oiv.no-permission.title": "Non dispone dell’accesso a questa pagina.\nSe ne ha bisogno, richieda il ruolo \"OIV Approval\".",
  "oiv.not-available.title": "La data di pubblicazione per questo contenuto non è stata ancora raggiunta.",
  "oiv.not-found.avb-overview": "Panoramica: Condizioni generali (www.mobiliare.ch)",
  "oiv.not-found.avb-overview-rednet": "E-biblioteca: Condizioni generali di assicurazione",
  "oiv.not-found.avb-overview-rednet.link": "https://services.marketing.mobiliere.ch/fr/downloads/e-bibliotheque?list.filter=%7B%22mobi:mawi.productgroup%22:%5B%22root.avb.%22%5D,%22censhare:asset.language%22:%5B%22it%22%5D%7D&s.text=&m.fset=true",
  "oiv.not-found.avb-overview.link": "https://mobiliare.ch/cga",
  "oiv.not-found.contact": "Contatto",
  "oiv.not-found.contact.link": "https://www.mobiliare.ch/contatto",
  "oiv.not-found.continue": "Avanti",
  "oiv.not-found.customer-portal": "Portale clienti «La mia Mobiliare»",
  "oiv.not-found.customer-portal.link": "https://www.mobiliare.ch/login",
  "oiv.not-found.heading.text": "Non ci è stato possibile trovare la pagina richiesta. Si prega di provare una delle seguenti opzioni per raggiungere il proprio obiettivo.",
  "oiv.not-found.heading.title": "Purtroppo brancoliamo nel buio.",
  "oiv.not-found.more-possibilities": "Altre possibilità di accesso",
  "oiv.placeholder.search": "Che cosa si sta cercando?"
}