/**
 * Escape all magic characters in a glob pattern.
 *
 * If the {@link windowsPathsNoEscape | GlobOptions.windowsPathsNoEscape}
 * option is used, then characters are escaped by wrapping in `[]`, because
 * a magic character wrapped in a character class can only be satisfied by
 * that exact character.  In this mode, `\` is _not_ escaped, because it is
 * not interpreted as a magic character, but instead as a path separator.
 */
export const escape = (s, {
  windowsPathsNoEscape = false
} = {}) => {
  // don't need to escape +@! because we escape the parens
  // that make those magic, and escaping ! as [!] isn't valid,
  // because [!]] is a valid glob class meaning not ']'.
  return windowsPathsNoEscape ? s.replace(/[?*()[\]]/g, '[$&]') : s.replace(/[?*()[\]\\]/g, '\\$&');
};
